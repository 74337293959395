/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import LogoImage from "../../assets/img/logo.png";
import { clearRedux, getInspectionVideos } from "../../redux/actions/adminActions";
import GuestLayout from "../Shared/GuestLayout";
import { useNavigate } from "react-router-dom";
import { INSPECTION_IMAGES_URL, DETAILS_UPLOAD_SCORE_URL, BIKE_CONDITIONS_URL } from "../Shared/constant";
import CommonLoader from "../Common/CommonLoader";
import WebcamCapture from "../Common/WebcamCapture";
import UploadVideoIcon from "../../assets/img/ri_video-upload-fill.svg";
import UploadRecordVideoIcon from "../../assets/img/video-camera.png";
import UploadFileAPI from "../../Api/UploadFileAPI";
import { WebSocketContext } from "../../App";
import moment from "moment";
import wsSend_request from "../../Api/ws/ws_request";
import ImagePreviwer from "../Common/ImagePreviwer";
import FranchiseHeader from "../Common/FranchiseHeader";
import VideoDelete from "../../assets/img/delete_video.svg";


const BikeCondition = (props) => {
    const { setGetCurrentPath } = props;
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const { websocket } = useContext(WebSocketContext);
    const { device_id, cacheClear_, ClearFormSet, dealerCustomerStatus, bikeconditionFilter, searchPincodeList, accessToken, typesofbicycle, customerdetails, bikePurchaseData, inspection_videos, bikeCondtionsSteps } = useSelector((state) => state.adminReducers);
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const [ InspectionVideos, setInspectionVideos ] = useState([]);
    const [ disable, setdisable ] = useState(false);

    // error state
    const [ errorInspectionVideos, seterrorInspectionVideos ] = useState("");

    useEffect(() => {
        setInspectionVideos(inspection_videos === undefined ? [] : inspection_videos);
    }, [ ]);

    const InspectionImagesVideo = [...InspectionVideos];

    // uplaod video
    const UploadVideo = async (e) => {
        setUploadFileLoader(true);
        const files = e.target.files[0];
        const formData = new FormData();
        formData.append("file", files, files.name);
        const fileresponse = await UploadFileAPI(formData);
        if (fileresponse?.status === 200) {
            InspectionImagesVideo?.push(fileresponse);
            setInspectionVideos(InspectionImagesVideo);
            dispatch(getInspectionVideos(InspectionImagesVideo));
            document.getElementById('fileInput').value = null;
            setUploadFileLoader(false);
            seterrorInspectionVideos("");
        } else {
            setUploadFileLoader(false);
        };
    };

    // remove video
    const RemoveVideo = (id) => {
        const UpdateInspectionImagesVideo = InspectionImagesVideo?.filter((elm) => {
            if(elm?.id !== id) {
                return elm;
            }
        });

        setInspectionVideos(UpdateInspectionImagesVideo);
    };

    // back steps function
    const BackStepFunction = () => {
        if(bikeCondtionsSteps !== 0) {
            Navigate(BIKE_CONDITIONS_URL);    
        };
    };

    // scroll top
    const OnSubmitScrollTop = () => {
        const GetId = document.getElementById('BikeConditionID');
        GetId?.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    // submit Inspection Images step
    const SubmitRegister = () => {
        if(InspectionVideos?.length > 0) {
            setdisable(true);
            const PointsGet = bikeconditionFilter?.filter((elz) => {
                if(typesofbicycle?.DoHaveSuspension?.value === "No") {
                    if(elz?.key !== "Suspension") {
                        return elz;    
                    };
                } else {
                    return elz;
                };
            })?.map((elm) => {
                return{
                    key: elm?.key,
                    value: elm?.value,
                    point_score: elm?.point_score,
                    Images: elm?.Images?.map((elz) => elz?.data?.id,            )
                }
            });

            
            let param = { 
                "transmit": "single", 
                "url": "refurbished_add_dynamic",
                "request" :  { 
                    "full_name" : customerdetails?.FullName, 
                    "email" : customerdetails?.Email, 
                    "brand" : bikePurchaseData?.Brand?.value === "Unbranded" ? bikePurchaseData?.BrandName : bikePurchaseData?.Brand?.value, 
                    "purchase_price" : bikePurchaseData?.PurchasePrice, 
                    "mobile": customerdetails?.Mobile, 
                    "is_geared" : typesofbicycle?.GearedType?.value === "Geared" ? true : false,
                    "no_geared" : typesofbicycle?.NoOfGeared, 
                    "frame_number" : typesofbicycle?.FrameNumber, 
                    "is_invoice" : typesofbicycle?.DoHaveInvoice?.value === "Yes" ? true : false, 
                    "is_suspension" : typesofbicycle?.DoHaveSuspension?.value === "Yes" ? true : false,
                    "invoice_image" : typesofbicycle?.invoiceImage, 
                    "purchase_year" : moment(typesofbicycle?.YearsMonths?.Year).format("yy"), 
                    "purchase_month" : moment(typesofbicycle?.YearsMonths?.Month).format("MM"), 
                    "points" : PointsGet, 
                    "inspection_videos" : InspectionVideos?.map((elm) => elm?.id),
                    // "token": accessToken
                },
                "DeviceId" : device_id,
            };
            if(dealerCustomerStatus?.customerExist) {
                param.request.pin_code_id = searchPincodeList?.pin?.id;
                param.request.city_id = searchPincodeList?.city?.id;
                param.request.state_id = searchPincodeList?.state?.id;
                param.request.token = null;
                // param.request.seller_type = "customer";
            } else {
                param.request.pin_code_id = null;
                param.request.city_id = null;
                param.request.state_id = null;
                param.request.token = accessToken;
                // param.request.seller_type = "dealer";
            };
            wsSend_request(websocket, param);
        } else {
            if(InspectionVideos?.length === 0) {
                seterrorInspectionVideos("Please upload inspection videos");
            };
            setdisable(false);
        }
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && ClearFormSet?.url == "refurbished_add_dynamic") {
            setTimeout(() => {
                Navigate(DETAILS_UPLOAD_SCORE_URL);
                setdisable(false);
            }, 500);
        };
    }, [ ClearFormSet ]);

    useEffect(() => {
        if(ClearFormSet?.action === 200 && ClearFormSet?.url === "purchase_add") {
            Navigate(INSPECTION_IMAGES_URL)
            setdisable(false);
        };
    }, [ ClearFormSet ]);

    return (<GuestLayout>
        <div className="loginWrapper MainregisterproductWrapper" >
            <div className="main_form_Box">
                <FranchiseHeader />
                <div className="loginMainblog Mainregisterproduct" >
                    <h4>Inspection Videos</h4>
                    <div className="formloginset">
                        <div className="radio_buttons_selections_wrap">
                            <div className="imageRefrance_wrapper">
                                <div className="formloginset">
                                    {screen.width <= 900 ? (<div className="tabmobile_buttons">
                                        <Form.Group className="mb-3" >
                                            <label className="btn uplaodvideo" id="uplaodvideo">
                                                <Form.Control type="file" id="fileInput" onChange={(e) => UploadVideo(e)} accept=".mp4, .mov"  />
                                                <Image src={UploadVideoIcon} alt="video" />
                                                Upload Video
                                            </label>
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <label className="btn uplaodvideo" id="uplaodvideo">
                                                <Form.Control type="file" id="fileInput" onChange={(e) => UploadVideo(e)} accept="video/mp4, video/quicktime" capture="camera" />
                                                <Image src={UploadRecordVideoIcon} alt="video" />
                                                Record and Upload Video
                                            </label>
                                        </Form.Group>
                                    </div>) : (<Form.Group className="mb-3" >
                                        <label className="btn uplaodvideo" id="uplaodvideo">
                                            <Form.Control type="file" id="fileInput" onChange={(e) => UploadVideo(e)} accept=".mp4, .mov"  />
                                            <Image src={UploadVideoIcon} alt="video" />
                                            Upload Video
                                        </label>
                                    </Form.Group>)}
                                    {errorInspectionVideos !== "" && <span className="error_medotory error_medotory_range">{errorInspectionVideos}</span>}
                                    
                                    {InspectionVideos?.map((elm, index) => {
                                        return(<Form.Group className="mb-3" key={index}>
                                            <label className="uploadfiles uploadfiles_video uploadfilesuploaded" aria-disabled={true}>
                                                <span>{elm?.name}</span>
                                                <Image src={VideoDelete} onClick={() => RemoveVideo(elm?.id)} alt="delete_video" />
                                            </label>
                                        </Form.Group>)
                                    })}
                                </div>
                            </div>
                            <Form.Group className="mb-3 btn-groups-wrapper" style={{ textAlign: "center" }}>
                                <Button className="backbtnbtn" id="registerbtn" onClick={() => BackStepFunction()}>
                                    Back
                                </Button>
                                <Button className="registerbtn" id="registerbtn" disabled={disable} onClick={() => SubmitRegister()}>
                                    Next
                                </Button>
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {UploadFileLoader && (<CommonLoader />)}
    </GuestLayout>)
}

export default BikeCondition;